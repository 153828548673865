<template>
  <!-- 出库 -->
  <el-dialog
    v-dialogDrag
    title="打款验证"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    width="800px"
    :before-close="handleClose"
  >
    <div class="formCentent">
      <el-form
        ref="amountFrom"
        :model="amountFrom"
        :rules="amountRules"
        label-position="left"
        label-width="120px"
        class="formItem"
      >
        <el-row :gutter="20">
          <el-col :span="22">
            <el-form-item label="认证金额" prop="amount">
              <el-input v-model="amountFrom.amount"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="22">
            <el-form-item label="银行流水号" prop="bankSerNum">
              <el-input v-model="amountFrom.bankSerNum"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="confirm()">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
/* global  protocolFwd http protocolNJP*/
export default {
  props: {},
  data() {
    return {
      dialogVisible: false,
      amountFrom: {
        amount: "",
        bankSerNum: ""
      },
      amountRules: {
        amount: [{ required: true, message: "请输入金额", trigger: "change" }],
        bankSerNum: [
          { required: true, message: "请输入银行流水号", trigger: "change" }
        ]
      }
    };
  },
  methods: {
    clearValidate() {
      this.amountFrom.amount = null;
      this.amountFrom.bankSerNum = null;
      this.$refs["form"].clearValidate();
    },
    showDialog(row) {
      this.dialogVisible = true;
    },
    // 提交
    confirm() {
      this.$refs["amountFrom"].validate(valid => {
        if (valid) {
          protocolFwd.param_comCustAuth.param = this.amountFrom;
          protocolFwd.param_comCustAuth.param.traderID = this.$store.state.common.sessionInfo.userId;
          http.postFrontMarket(protocolFwd.param_comCustAuth).then(response => {
            const { code, message, value } = response.data;
            if (Number(code) == 0) {
              if (Number(value.code) == 0) {
                this.$EL_MESSAGE(message);
                this.$emit("reLoad");
                this.dialogVisible = false;
              } else {
                this.$EL_MESSAGE(value.message);
                // this.$emit('reLoad')
                // this.dialogVisible = false;
              }
            } else {
              this.$EL_MESSAGE(message);
            }
          });
        }
      });
    },
    handleClose() {
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
      this.amountFrom.amount = null;
      this.amountFrom.bankSerNum = null;
      this.dialogVisible = false;
    }
  }
};
</script>
<style scoped lang="scss">
.formCentent {
  width: 600px;
  margin: 0 auto;
}
</style>
