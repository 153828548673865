<!-- tsx -->
<template>
  <div class="baseHeader">
    <section class="toHeader">
      <div class="left">
        <span style="cursor: pointer;font-size:16px;" @click="goBack()">
          <i class="el-icon-arrow-left" style="color: #000;font-size:16px"></i>{{ leftTit }}
        </span>
      </div>
    </section>
  </div>
</template>

<script >
export default {
  props: ["leftTit"],
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {},
  watch: {},
  methods: {
    goBack(){
      console.log('123456')
      this.$router.push({
        path:'/conditionOfAssets'
      })
    },
  },
};
</script>

<style lang='scss' scoped>
// @import url(); 引入公共css类\.
.baseHeader{
  margin: 20px auto;
  padding: 0 40px;
  width: 1000px;
  height: 60px;
  // margin-bottom: 24px;
  // background-color: $themeBaseColor;
  // background-color: $baseHeaderBGColor;
  background-color: #fff;
  border-radius: 8px;
}
.toHeader {
  height: 60px;
  line-height: 60px;
  margin: 0 auto;
  // padding-top: 45px;
  .left {
    font-size: 16px;
    color: #000;
  }
}
</style>
